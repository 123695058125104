<template>
	<div class="home-page">
		<ori-header />
		<div class="top-header">
			<video
				id="video"
				autoplay="autoplay"
				muted="muted"
				loop="loop"
				poster="https://storage.djyanbao.com/dj-static/web/images/index_bg.png"
			>
				<source
					src="https://storage.djyanbao.com/dj-static/web/videos/index_bg.mp4"
					type="video/mp4"
				/>
			</video>
			<div class="video-content contailer">
				<div class="logo-box">
					<img src="@/assets/images/logo-page.webp" alt="" />
				</div>
				<div class="search-subtitle">
					<a href="#" @click="$router.push('/ori-search-detail')">行业研究</a> |
					<a href="#" @click="$router.push('/ori-search-detail')">宏观策略</a> |
					<a href="#" @click="$router.push('/ori-search-detail')">管理咨询</a> |
					<a href="#" @click="$router.push('/ori-search-detail')">产业链贯穿</a>
					|
					<a href="#" @click="$router.push('/ori-search-detail')">公司深挖</a> |
					<a href="#" @click="$router.push('/ori-search-detail')">招股书</a> |
					<a href="#" @click="$router.push('/ori-search-detail')">政策法规</a>
				</div>
				<div class="search-input-box">
					<div class="search-input">
						<el-input
							placeholder="搜索关键词、行业、公司等…（多个关键词可用空格分开）"
							class="input-with-select"
						>
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</div>
				</div>
				<div class="search-hot">
					<span @click="$router.push('/ori-search-detail')">热门搜索：</span>
					<span @click="$router.push('/ori-search-detail')">元宇宙</span>
					<span @click="$router.push('/ori-search-detail')">电子烟</span>
					<span @click="$router.push('/ori-search-detail')">机器人</span>
					<span @click="$router.push('/ori-search-detail')">用工成本</span>
					<span @click="$router.push('/ori-search-detail')">Web3.0</span>
					<span @click="$router.push('/ori-search-detail')">专精特新</span>
				</div>
				<div class="dataCount-box">
					<div class="item" @click="$router.push('/ori-search-detail')">
						<div class="num-title">
							<div class="num"><span> 2,600,000 </span><span>+</span></div>
							<div class="report">专业报告</div>
						</div>
					</div>
					<div class="item" @click="$router.push('/ori-search-detail')">
						<div class="num-title">
							<div class="num">
								<span>
									2,723
								</span>
							</div>
							<div class="report">昨日新增</div>
						</div>
					</div>
					<div class="item" @click="$router.push('/ori-search-detail')">
						<div class="num-title">
							<div class="num"><span> 3,000 </span><span>+</span></div>
							<div class="report">权威机构</div>
						</div>
					</div>
					<div class="item" @click="$router.push('/ori-search-detail')">
						<div class="num-title">
							<div class="num"><span> 8,350,000 </span><span>+</span></div>
							<div class="report">图表数据</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bgwhite">
				<div class="rank contailer">
					<div class="rank-item">
						<div class="top">
							<img src="@/assets/images/rank-1.webp" alt="" />
						</div>
						<div class="list">
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/one.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/two.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/three.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									4
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									5
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									6
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="rank-item">
						<div class="top">
							<img src="@/assets/images/rank-2.webp" alt="" />
						</div>
						<div class="list">
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/one.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/two.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/three.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									4
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									5
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									6
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="rank-item">
						<div class="top">
							<img src="@/assets/images/rank-3.webp" alt="" />
						</div>
						<div class="list">
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/one.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/two.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									<img src="@/assets/images/three.png" alt="" />
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									4
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									5
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
							<div class="list-item" @click="$router.push('/ori-preview')">
								<div class="no">
									6
								</div>
								<div class="info">
									<div class="info-title clearFix">
										居家健身行业研究报告（居家健身、健身、健身直播、室内健身、Keep、健身器材、刘畊宏）
									</div>
									<div class="info-desc">
										<span class="time">2022-04-25</span>
										<div class="resource">
											洞见研报
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="industry">
				<div class="industry-box contailer">
					<div class="industry-choose">
						<div
							class="industry-name"
							@click="active = 1"
							:class="{ active: active === 1 }"
						>
							科技传媒
						</div>
						<div
							class="industry-name"
							@click="active = 2"
							:class="{ active: active === 2 }"
						>
							大消费
						</div>
						<div
							class="industry-name"
							@click="active = 3"
							:class="{ active: active === 3 }"
						>
							健康医疗
						</div>
						<div
							class="industry-name"
							@click="active = 4"
							:class="{ active: active === 4 }"
						>
							金融地产
						</div>
						<div
							class="industry-name"
							@click="active = 5"
							:class="{ active: active === 5 }"
						>
							能源矿产
						</div>
						<div
							class="industry-name"
							@click="active = 6"
							:class="{ active: active === 6 }"
						>
							工业制造
						</div>
						<div
							class="industry-name"
							@click="active = 7"
							:class="{ active: active === 7 }"
						>
							交通物流
						</div>
						<div
							class="industry-name"
							@click="active = 8"
							:class="{ active: active === 8 }"
						>
							公共服务
						</div>
						<div
							class="industry-name"
							@click="active = 9"
							:class="{ active: active === 9 }"
						>
							农林牧渔
						</div>
						<div
							class="industry-name"
							@click="active = 10"
							:class="{ active: active === 10 }"
						>
							综合其他
						</div>
					</div>
					<div class="content-item">
						<div class="box-item">
							<div class="image">
								<img src="@/assets/images/box-item.png" alt="" />
							</div>
							<div class="txt">
								<div class="title clearFix">
									完成改制优化成长路径，多点开花释放增长
								</div>
								<div class="btn">
									华工科技
								</div>
								<div class="name">
									张旭
								</div>
								<div class="orgname">
									<div class="date">
										2022-04-28
									</div>
									<span>国联证券</span>
								</div>
							</div>
						</div>
						<div class="box-item">
							<div class="image">
								<img src="@/assets/images/box-item.png" alt="" />
							</div>
							<div class="txt">
								<div class="title clearFix">
									完成改制优化成长路径，多点开花释放增长
								</div>
								<div class="btn">
									华工科技
								</div>
								<div class="name">
									张旭
								</div>
								<div class="orgname">
									<div class="date">
										2022-04-28
									</div>
									<span>国联证券</span>
								</div>
							</div>
						</div>
						<div class="box-item">
							<div class="image">
								<img src="@/assets/images/box-item.png" alt="" />
							</div>
							<div class="txt">
								<div class="title clearFix">
									完成改制优化成长路径，多点开花释放增长
								</div>
								<div class="btn">
									华工科技
								</div>
								<div class="name">
									张旭
								</div>
								<div class="orgname">
									<div class="date">
										2022-04-28
									</div>
									<span>国联证券</span>
								</div>
							</div>
						</div>
						<div class="box-item">
							<div class="image">
								<img src="@/assets/images/box-item.png" alt="" />
							</div>
							<div class="txt">
								<div class="title clearFix">
									完成改制优化成长路径，多点开花释放增长
								</div>
								<div class="btn">
									华工科技
								</div>
								<div class="name">
									张旭
								</div>
								<div class="orgname">
									<div class="date">
										2022-04-28
									</div>
									<span>国联证券</span>
								</div>
							</div>
						</div>
						<div class="box-item">
							<div class="image">
								<img src="@/assets/images/box-item.png" alt="" />
							</div>
							<div class="txt">
								<div class="title clearFix">
									完成改制优化成长路径，多点开花释放增长
								</div>
								<div class="btn">
									华工科技
								</div>
								<div class="name">
									张旭
								</div>
								<div class="orgname">
									<div class="date">
										2022-04-28
									</div>
									<span>国联证券</span>
								</div>
							</div>
						</div>
						<div class="box-item">
							<div class="image">
								<img src="@/assets/images/box-item.png" alt="" />
							</div>
							<div class="txt">
								<div class="title clearFix">
									完成改制优化成长路径，多点开花释放增长
								</div>
								<div class="btn">
									华工科技
								</div>
								<div class="name">
									张旭
								</div>
								<div class="orgname">
									<div class="date">
										2022-04-28
									</div>
									<span>国联证券</span>
								</div>
							</div>
						</div>
					</div>
					<div class="look-more">
						<div>查看更多 <i class="el-icon-arrow-right"></i></div>
					</div>
				</div>
				<div class="register">
					<div class="slogan contailer">
						<div class="title">
							现在注册·免费领取报告集礼物等福利
						</div>
						<span class="ball ball1">投资机遇</span
						><span class="ball ball2">竞对动态</span
						><span class="ball ball3">大趋势</span
						><span class="ball ball4">行业动态</span>
					</div>
					<div class="register-btn">立即注册</div>
				</div>
			</div>
			<div class="bgwhite">
				<div class="industry-chain contailer">
					<div class="title">
						<img src="@/assets/images/left.png" alt="" />
						<span>全面覆盖十大行业、各产业链</span>
						<img src="@/assets/images/right.png" alt="" />
					</div>
					<div class="subtitle">Industry coverage</div>
					<div class="industry-items">
						<div class="flex">
							<span class="pointer">科技传媒</span
							><span class="pointer">大消费</span
							><span class="pointer">健康医疗</span
							><span class="pointer">金融地产</span
							><span class="pointer">能源矿产</span
							><span class="pointer">工业制造</span
							><span class="pointer">交通物流</span
							><span class="pointer">公共服务</span
							><span class="pointer">农林牧渔</span
							><span class="pointer">综合其他</span>
						</div>
					</div>
				</div>
			</div>
			<div class="industry">
				<div class="bggray contailer">
					<div class="title">
						<img src="@/assets/images/left.png" alt="" />
						<span>这些机构选择了我们</span>
						<img src="@/assets/images/right.png" alt="" />
					</div>
					<div class="subtitle">Selected Clients</div>
					<el-carousel :interval="5000" arrow="always" height="550px">
						<el-carousel-item>
							<div class="card">
								<div class="card-item" v-for="i in 12" :key="i">
									<img src="@/assets/images/logo1.png" alt="" />
								</div>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="card">
								<div class="card-item" v-for="i in 12" :key="i">
									<img src="@/assets/images/logo2.jpg" alt="" />
								</div>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="card">
								<div class="card-item" v-for="i in 12" :key="i">
									<img src="@/assets/images/logo3.png" alt="" />
								</div>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="card">
								<div class="card-item" v-for="i in 12" :key="i">
									<img src="@/assets/images/logo4.jpg" alt="" />
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
					<div class="bg-top"></div>
				</div>
				<a href="#">
					<img
						src="@/assets/images/home-page-banner2.jpg"
						alt=""
						style="width:100%"
					/>
				</a>
				<div class="bggray contailer">
					<div class="title">
						<img src="@/assets/images/left.png" alt="" />
						<span>深度合作伙伴</span>
						<img src="@/assets/images/right.png" alt="" />
					</div>
					<div class="subtitle">Deep Partner</div>
					<div class="detail-box">
						<div class="choose-img">
							<img src="@/assets/images/logo1.png" alt="" />
						</div>
						<div class="choose-info">
							<div class="text">
								<div class="choosed-title">
									<span>灼鼎咨询</span>
								</div>
								<div class="desc">
									灼鼎咨询专业提供行业市场研究与产业深度调研，通过广泛的专家网络和专业的咨询经验，为客户提供上市IPO行业数据支持、一级市场投融资、尽职调查、行业对标调研等服务！在新能源、新经济、新消费等领域有深厚的积累，同时海外市场调研资源丰富！
								</div>
							</div>
							<a href="#">www.apexandconsulting.com</a>
						</div>
					</div>
					<el-carousel :interval="5000" arrow="always" height="550px">
						<el-carousel-item>
							<div class="card">
								<div
									class="card-item"
									v-for="(i, index) in 12"
									:key="index"
									@click="activeCard = index"
									:class="{ activeCard: activeCard === index }"
									style="cursor: pointer;"
								>
									<img src="@/assets/images/logo1.png" alt="" />
								</div>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="card">
								<div
									class="card-item"
									v-for="(i, index) in 12"
									:key="index"
									@click="activeCard = index"
									:class="{ activeCard: activeCard === index }"
									style="cursor: pointer;"
								>
									<img src="@/assets/images/logo2.jpg" alt="" />
								</div>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="card">
								<div
									class="card-item"
									v-for="(i, index) in 12"
									:key="index"
									@click="activeCard = index"
									:class="{ activeCard: activeCard === index }"
									style="cursor: pointer;"
								>
									<img src="@/assets/images/logo3.png" alt="" />
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<page-foot />
		<or-code />
	</div>
</template>

<script>
import PageFoot from '../../../components/ori-com/PageFoot.vue'
import OrCode from '../../../components/ori-com/Orcode.vue'
export default {
	components: {
		PageFoot,
		OrCode,
	},
	data() {
		return {
			active: 1,
			activeCard: 0,
		}
	},
	methods: {},
}
</script>

<style scoped lang="scss">
.home-page {
	color: #202a33;
	font-family: PingFangSC-Regular, PingFang SC;
	.contailer {
		width: 1200px;
		margin: auto;
	}
	.clearFix {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
	.title {
		color: #202a33;
		font-size: 28px;
		img {
			width: 82px;
			height: 24px;
			margin: 0 20px;
		}
	}
	.subtitle {
		margin-top: 10px;
		font-size: 16px;
		color: #a8b4bf;
		letter-spacing: 4px;
	}
	.detail-box {
		margin-top: 80px;
		display: flex;
		justify-content: space-between;
		.choose-img {
			width: 600px;
			height: 220px;
			background: #fff;
			border-radius: 8px;
			line-height: 220px;
		}
		.choose-info {
			width: 570px;
			text-align: left;
			position: relative;
			.choosed-title {
				max-width: 100%;
				font-size: 20px;
				font-weight: 500;
				color: #202a33;
				line-height: 34px;
				margin-bottom: 20px;
			}
			.desc {
				font-size: 14px;
				font-weight: 400;
				color: #394551;
				line-height: 22px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 6;
			}
			a {
				position: absolute;
				bottom: 0;
				color: #327bfa;
			}
		}
	}
	.top-header {
		// height: 800px;
		// width: 100%;
		#video {
			width: 100%;
			height: 800px;
			object-fit: cover;
			position: absolute;
			top: 75px;
			left: 0;
			z-index: -1;
			opacity: 1;
		}
		.video-content {
			.logo-box {
				margin: 120px 0 34px;
				img {
					width: 356px;
					height: 74px;
				}
			}
			.search-subtitle {
				letter-spacing: 3px;
				text-align: left;
				font-family: PingFangSC-Regular, PingFang SC;
				height: 36px;
				font-size: 18px;
				font-weight: 400;
				color: #fff;
				line-height: 36px;
				a {
					color: #fff;
				}
				a:hover {
					color: #327bfa;
					text-decoration: underline;
				}
			}
			.search-input-box {
				position: relative;
				width: 780px;
				margin: 100px 0 40px;
				.search-input {
					position: relative;
					width: 780px;
					border-radius: 4px;
					overflow: auto;
					::v-deep {
						.el-input__inner {
							height: 68px;
							width: 700px;
						}
						.el-input-group__append {
							width: 80px;
							text-align: center;
							color: #fff;
							background-color: #327bfa;
							font-size: 24px;
						}
					}
				}
			}
			.search-hot {
				span {
					margin-right: 30px;
					color: #fff;
					font-size: 14px;
					cursor: pointer;
				}
				span:first-child {
					margin-right: 0;
				}
				span:hover {
					color: #327bfa;
				}
			}
			.dataCount-box {
				margin-top: 130px;
				display: flex;
				.item {
					flex: 1;
					border-right: 1px solid #fff;
					cursor: pointer;
					margin-bottom: 50px;
					.num-title {
						color: #fff;
						text-align: center;
						.num {
							font-size: 32px;
							font-weight: 600;
						}
						.report {
							margin-top: 16px;
						}
					}
				}
				.item:last-child {
					border-right: none;
				}
			}
		}
	}
	.bgwhite {
		background-color: #fff;
		.rank {
			padding-top: 60px;
			padding-bottom: 60px;
			display: flex;
			justify-content: space-between;
			.rank-item {
				// background-color: yellow;
				border: 1px solid #ebeef5;
				box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
				width: 370px;
				.top {
					img {
						width: 368px;
						height: 100px;
					}
				}
				.list {
					cursor: pointer;
					.list-item {
						margin: 14px 20px;
						display: flex;
						border-bottom: 1px solid #ebeef5;
						.no {
							width: 26px;
							height: 26px;
							margin-right: 12px;
							font-size: 16px;
							color: #327bfa;
							text-align: center;
							line-height: 26px;
							flex: none;
							img {
								width: 26px;
								height: 26px;
							}
						}
						.info {
							.info-title:hover {
								color: #327bfa;
							}
							.info-desc {
								display: flex;
								font-size: 12px;
								color: #a8b4bf;
								padding: 14px 0;
								.time {
									margin-right: 20px;
								}
								.resource:hover {
									color: #327bfa;
								}
							}
						}
					}
					.list-item:last-child {
						border-bottom: none;
					}
				}
			}
		}
		.industry-chain {
			padding-top: 60px;
			text-align: center;
			padding-bottom: 50px;
			.industry-items {
				margin-top: 60px;
				.flex {
					display: flex;
					margin: 0 auto;
					margin-top: 25px;
					max-width: 1000px;
					justify-content: center;
					flex-wrap: wrap;
					span {
						margin: 12.5px;
						width: 128px;
						height: 40px;
						line-height: 38px;
						color: #327bfa;
						background: #f9fafb;
						border-radius: 4px;
						border: 1px solid #327bfa;
					}
				}
			}
		}
	}
	.industry {
		background-color: #f7f7f7;
		.industry-box {
			.industry-choose {
				padding-top: 60px;
				display: flex;
				.industry-name {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #707d8a;
					padding: 11px 28px;
					cursor: pointer;
				}
				.active {
					border-radius: 22px;
					font-size: 16px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #327bfa;
					line-height: 22px;
					background: #d8ebff;
				}
			}
		}
		.content-item {
			.box-item {
				margin-top: 88px;
				position: relative;
				width: 386px;
				height: 138px;
				background: #fff;
				box-shadow: 0 10px 20px 0 rgb(213 229 255 / 40%);
				display: inline-block;
				margin-right: 21px;
				.image {
					img {
						width: 127px;
						height: 162px;
						position: absolute;
						left: 14px;
						bottom: 14px;
					}
				}
				.txt {
					width: 217px;
					margin-left: 155px;
					font-family: PingFangSC-Regular, PingFang SC;
					.title {
						height: 58px;
						font-size: 14px;
						font-weight: 400;
						color: #202a33;
						line-height: 22px;
						padding-top: 14px;
						cursor: pointer;
					}
					.btn {
						cursor: pointer;
						height: 22px;
						width: 60px;
						border-radius: 4px;
						padding: 2px 6px;
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
						margin: 4px 0;
						cursor: pointer;
						color: #327bfa;
						background: #d8ebff;
					}
					.name {
						width: 220px;
						height: 18px;
						font-size: 12px;
						font-weight: 400;
						color: #a8b4bf;
						line-height: 18px;
						margin: 2px 0;
					}
					.orgname {
						display: flex;
						font-size: 12px;
						font-weight: 400;
						color: #a8b4bf;
						.date {
							margin-right: 30px;
						}
					}
				}
			}
			.box-item:nth-child(3),
			.box-item:nth-child(6) {
				margin-right: 0;
			}
		}
		.look-more {
			margin-top: 50px;
			cursor: pointer;
			font-size: 16px;
			color: #83889c;
			line-height: 22px;
			text-align: center;
		}
		.bggray {
			text-align: center;
			padding-top: 80px;
			::v-deep {
				.el-carousel__arrow {
					display: none;
				}
				.el-carousel__indicator.is-active button {
					width: 32px;
					height: 4px;
					background-color: #a8b4bf;
				}
				.el-carousel__button {
					width: 32px;
					height: 4px;
					background-color: #c7d2dc;
				}
			}
			.card {
				margin: 80px 0;
				.card-item {
					width: 268px;
					height: 100px;
					margin-right: 32px;
					margin-bottom: 32px;
          overflow: hidden;
					border-radius: 8px;
					background: #fff;
					line-height: 100px;
					display: inline-block;
					img {
						width: 150px;
						height: 60px;
						background: #fff;
					}
				}
				.activeCard {
					border: 1px solid #327bfa;
          line-height: 98px;
				}
			}
			.bg-top {
				height: 80px;
			}
		}
	}
	.register {
		height: 220px;
		color: #fff;
		text-align: center;
		background: url(~@/assets/images/home-page-banner.png) no-repeat 50%;
		background-size: cover;
		overflow: hidden;
		margin-top: 60px;
		.slogan {
			width: 714px;
			padding-top: 46px;
			font-size: 32px;
			position: relative;
			.title {
				color: #fff;
			}
			.ball {
				position: absolute;
				line-height: 82px;
				font-size: 16px;
				color: hsla(0, 0%, 100%, 0.6);
				background: url(~@/assets/images/ball.png) no-repeat 50%;
				background-size: contain;
			}
			.ball1 {
				top: 20px;
				left: -250px;
				width: 82px;
				height: 118px;
			}
			.ball2 {
				top: 105px;
				left: -120px;
				width: 65px;
				height: 94px;
				font-size: 12px;
				line-height: 65px;
			}
			.ball3 {
				top: 44px;
				right: -240px;
				width: 82px;
				height: 118px;
			}
			.ball4 {
				top: 115px;
				right: -80px;
				width: 65px;
				height: 94px;
				font-size: 12px;
				line-height: 65px;
			}
		}
		.register-btn {
			margin: 32px auto;
			width: 240px;
			height: 52px;
			line-height: 52px;
			font-size: 22px;
			text-align: center;
			background: #327bfa;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
</style>
